import React from 'react';

import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

import {
  ANDROID_RATING_COUNT,
  AVERAGE_APP_RATING,
  IOS_ALL_RATING_COUNT,
  IOS_APP_RATING,
} from '../../constants/constants';
import { useClientHasRendered } from '../../helpers/hooks';
import { useIsAndroid } from '../../helpers/hooks/useIsAndroid';
import { Heading } from '../Heading/Heading';
import { Rating, RatingVariant } from '../Rating/Rating';
import { Text } from '../Text/Text';

import { AppRatingImageLoader } from './components/AppRatingImageLoader';
import { AppRatingLoader } from './components/AppRatingLoader';
import editorsChoice from './editorsChoice.svg';

interface IProps {
  className?: string;
  bottomOfPage?: boolean;
}

export const AppRatingSection = ({ className = '', bottomOfPage = false }: IProps) => {
  const isAndroid = useIsAndroid();
  const clientHasRendered = useClientHasRendered();

  return (
    <div
      className={twMerge(
        `flex ${
          bottomOfPage
            ? 'mb-4 flex-col items-center sm:flex-row sm:justify-center sm:space-x-12'
            : 'items-start justify-between sm:flex-col'
        }`,
        className,
      )}
      data-testid="AppRatingSection"
    >
      <div
        className={`flex flex-col md:flex-col md:items-center ${
          bottomOfPage ? 'items-center' : 'items-start'
        }`}
      >
        <div className="flex items-baseline md:flex-col">
          {bottomOfPage ? (
            <Rating
              rating={isAndroid ? AVERAGE_APP_RATING : IOS_APP_RATING}
              variant={RatingVariant.allStars}
              textSize="sm"
              hideLabel
              isLarge
              className="mb-2"
            />
          ) : (
            <Rating
              rating={isAndroid ? AVERAGE_APP_RATING : IOS_APP_RATING}
              variant={RatingVariant.allStars}
              textSize="sm"
            />
          )}
        </div>
        {clientHasRendered ? (
          <>
            {bottomOfPage && (
              <Heading as="span" variant="2xs" className="pb-2">
                {isAndroid ? `${AVERAGE_APP_RATING} out of 5` : `${IOS_APP_RATING} out of 5`}
              </Heading>
            )}
            {isAndroid && (
              <Text variant="sm" as="span" className="ml-1">
                average rating
              </Text>
            )}
            <Text variant="sm" as="span" className="ml-1 font-semibold">
              {isAndroid
                ? `${ANDROID_RATING_COUNT} app store ratings`
                : `${IOS_ALL_RATING_COUNT} ratings`}
            </Text>
          </>
        ) : (
          <AppRatingLoader bottomOfPage={bottomOfPage} />
        )}
      </div>

      <div className="flex flex-col justify-start">
        {clientHasRendered ? (
          <Image
            alt="editor's choice"
            width={95}
            height={59}
            className={`mt-4 ${bottomOfPage ? 'sm:mt-0' : ''}`}
            src={editorsChoice}
          />
        ) : (
          <AppRatingImageLoader bottomOfPage={bottomOfPage} />
        )}
      </div>
    </div>
  );
};
